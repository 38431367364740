import React, { useState } from "react";
import "../../styles.css";

const Menu = ({ height }) => {

        const [navs, setNavs] = useState({ 
        somos_protagonistas: false,
        a_voz_alzada: false,
        videos: false,
        afiches_folleterias: false,
        spots_audiovisuales: false,
    });

    function activateNav(nav) {
        setNavs((prevState) => ({
        somos_protagonistas: false,
        a_voz_alzada: false,
        videos: false,
        afiches_folleterias: false,
        spots_audiovisuales: false,
        [nav]: !prevState[nav],
        }));
      }

    const url = window.location.href

    return (
        <>
            <nav id="nav-vertical" className="nav" style={{height: height, background: "#51B8B1"}}>
                <ul className="list">
                    {window.innerWidth <901 && (
                    <li className="list__item list__item--click encodesans-bold">
                        <div 
                            className="list__button"
                        >
                            <button className="btn-home__mobile encodesans-bold" onClick={() => window.location.href = '/'}> {/* Cambiamos <a> por <button> y manejamos la redirección con onClick */}
                                Inicio
                            </button>
                        </div>
                    </li>
                    )}

                    <li className="list__item list__item--click encodesans-bold">
                        <div 
                            className="list__button list__button--click"
                            onClick={() => activateNav('somos_protagonistas')}
                        >
                            <a  className="nav__link">
                                Somos Protagonistas: Recursos para la acción
                            </a>
                        </div>

                        <ul className={`list__sub__item ${ navs.somos_protagonistas ? 'list__show' : 'list__hide' }`}>
                            <li className="list__inside encodesans-semiBold">
                                <a href="/juventudes" className="nav__link nav__link--inside">
                                Juventudes
                                </a>
                            </li>
                            <li className="list__inside encodesans-semiBold">
                                <a href="/nineces" className="nav__link nav__link--inside">
                                    Niñeces
                                </a>
                            </li>
                            <li className="list__inside encodesans-semiBold">
                                <a href="/promotoresterritoriales" className="nav__link nav__link--inside">
                                    Promotores Territoriales de Género y Diversidad
                                </a>
                            </li>
                            <li className="list__inside encodesans-semiBold">
                                <a href="/Espaciosculturales" className="nav__link nav__link--inside">
                                Espacios culturales y comunitarios
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className="list__item encodesans-bold">
                        <div 
                            className="list__button list__button--click"
                        >
                            <a 
                                target="_blank" rel="noopener noreferrer"
                                href="https://identidades.cultura.gob.ar/2022/09/06/concurso-federal-canciones-por-una-argentina-sin-violencias-de-genero/" 
                                className="nav__link"
                            >
                                Cancionero “Canciones por una Argentina sin Violencias de
                                Género”
                            </a>
                        </div>
                    </li>
                    <li className="list__item encodesans-bold">
                        <div 
                            className="list__button list__button--click"
                            onClick={() => activateNav('a_voz_alzada')}
                        >
                            <a  className="nav__link">
                                Ilustraciones "A voz alzada"
                            </a>
                        </div>
                        <ul className={`list__sub__item ${ navs.a_voz_alzada ? 'list__show' : 'list__hide' }`}>
                            <li className="list__inside encodesans-semiBold">
                                <a href="/avozalzada" className="nav__link nav__link--inside">
                                    Ilustraciones A voz alzada 
                                </a>
                            </li>
                            <li className="list__inside encodesans-semiBold">
                                <a href="/soymora" className="nav__link nav__link--inside">
                                    Ilustraciones por @soymora sobre Violencia Política 
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className="list__item list__item--click encodesans-bold">
                        <div 
                            className="list__button list__button--click"
                            onClick={() => activateNav('afiches_folleterias')}
                        >
                            <a  className="nav__link">
                                Afiches y folletería para descargar
                            </a>
                        </div>
                        <ul className="list__sub__item" style={{ display: navs.afiches_folleterias ? 'block' : 'none' }}>
                            <li className="list__inside encodesans-semiBold">
                                <a href="/downloads/masculinidades/Afiche ¿Qué podemos hacer los varones_.pdf" target="_blank" className="nav__link nav__link--inside">
                                Afiche ¿Qué podemos hacer los varones?
                                </a>
                            </li>
                            <li className="list__inside encodesans-semiBold">
                                <a href="/downloads/masculinidades/Folleto ¿Qué podemos hacer los varones_.pdf" target="_blank" className="nav__link nav__link--inside">
                                    Folleto ¿Qué podemos hacer los varones?
                                </a>
                            </li>
                            <li className="list__inside encodesans-semiBold">
                                <a href="/downloads/estereotiposGenero/Folleto_sobre_estereotipos_de_genero.pdf" target="_blank" className="nav__link nav__link--inside">
                                    Folleto sobre estereotipos de género
                                </a>
                            </li>
                            <li className="list__inside encodesans-semiBold">
                                <a href="/downloads/identificacionViolenciasDeGenero/Folleto_sobre_tipos_y_modalidades_de_violencias.pdf" target="_blank" className="nav__link nav__link--inside">
                                Folleto sobre tipos y modalidades de violencia de género
                                </a>
                            </li>
                            <li className="list__inside encodesans-semiBold">
                                <a href="/downloads/identificacionViolenciasDeGenero/Folleto_sobre_violencia_sexual.pdf" target="_blank" className="nav__link nav__link--inside">
                                    Folleto Violencia Sexual
                                </a>
                            </li>
                            <li className="list__inside encodesans-semiBold">
                                <a href="/downloads/identificacionViolenciasDeGenero/Afiche sobre Violencia Sexual.pdf" target="_blank" className="nav__link nav__link--inside">
                                    Afiche Violencia Sexual
                                </a>
                            </li>
                            <li className="list__inside encodesans-semiBold">
                                <a href="/downloads/vinculosResponsables/Folleto-Noches_consentidas.pdf" target="_blank" className="nav__link nav__link--inside">
                                    Folleto Noches Consentidas
                                </a>
                            </li>
                            <li className="list__inside encodesans-semiBold">
                                <a href="/downloads/vinculosResponsables/Afiche_Noches_consentidas .pdf" target="_blank" className="nav__link nav__link--inside">
                                    Afiche Noches Consentidas
                                </a>
                            </li>
                        </ul>
                    </li>


                    <li className="list__item encodesans-bold">
                        <div 
                            className="list__button list__button--click"
                            onClick={() => activateNav('spots_audiovisuales')}
                        >
                            <a  className="nav__link">
                                Videos
                            </a>
                        </div>
                        <ul className="list__sub__item" style={{ display: navs.spots_audiovisuales ? 'block' : 'none' }}>
                            <li className="list__inside encodesans-semiBold">
                                <a href="https://www.youtube.com/watch?v=MfNcyAgrprQ&list=PLdErRfhsb4srODnKnaqIAhYAr51nJXjgu&index=9&ab_channel=MinisteriodelasMujeres%2CG%C3%A9nerosyDiversidad" target="_blank" className="nav__link nav__link--inside">
                                    Prevención de la violencia de Género en Ámbitos Laborales
                                </a>
                            </li>
                            <li className="list__inside encodesans-semiBold">
                                <a href="https://www.youtube.com/watch?v=M46Lb2l5b04&list=PLdErRfhsb4srODnKnaqIAhYAr51nJXjgu&index=14&ab_channel=MinisteriodelasMujeres%2CG%C3%A9nerosyDiversidad" target="_blank" className="nav__link nav__link--inside">
                                Hablemos de Violencia Política
                                </a>
                            </li>
                            <li className="list__inside encodesans-semiBold">
                                <a href="https://www.youtube.com/watch?v=Xiu6hzB0f0c&list=PLdErRfhsb4srODnKnaqIAhYAr51nJXjgu&index=10&ab_channel=MinisteriodelasMujeres%2CG%C3%A9nerosyDiversidad" target="_blank" className="nav__link nav__link--inside">
                                Hablemos con Juventudes de estereotipos, masculinidades, vínculos, y violencias de género 
                                </a>
                            </li>
                            <li className="list__inside encodesans-semiBold">
                                <a href="https://www.youtube.com/watch?v=S6yLPbSjM6s&list=PLdErRfhsb4srODnKnaqIAhYAr51nJXjgu&index=2&ab_channel=MinisteriodelasMujeres%2CG%C3%A9nerosyDiversidad" target="_blank" className="nav__link nav__link--inside">
                                Empecemos a pensarlo #ArgentinaSinViolencias 
                                </a>
                            </li>
                            <li className="list__inside encodesans-semiBold">
                                <a href="https://www.youtube.com/watch?v=pcfVroBLPsI&list=PLdErRfhsb4srODnKnaqIAhYAr51nJXjgu&index=15&ab_channel=MinisteriodelasMujeres%2CG%C3%A9nerosyDiversidad" target="_blank" className="nav__link nav__link--inside">
                                De promotor a promotor: Escucha Activa
                                </a>
                            </li>
                            <li className="list__inside encodesans-semiBold">
                                <a href="https://www.youtube.com/watch?v=GY_8bPDNq-4&list=PLdErRfhsb4srODnKnaqIAhYAr51nJXjgu&index=16&ab_channel=MinisteriodelasMujeres%2CG%C3%A9nerosyDiversidad"target="_blank" className="nav__link nav__link--inside">
                                De promotor a promotor: Interseccionalidad
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className="list__item_map encodesans-bold">
                        <div 
                            className="list__button list__button--click"
                        >
                            <a 
                                target="_blank" rel="noopener noreferrer"
                                href="https://mevym.mingeneros.gob.ar/" 
                                className="nav__link"
                            >
                                Mapa Federal de Experiencias con Varones y Masculinidades
                                (MEVyM)
                            </a>
                        </div>
                    </li>
                </ul>
            </nav>
        </>
    );

};

export default Menu;
