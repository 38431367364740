import React, {useState, useEffect} from 'react';
import Header from '../../components/header/index';
import Footer from '../../components/footer/index';
import Paragraph from '../../components/paragraph/index';
import Buttons from '../../components/buttons/index';
import VideoButton from '../../components/videoButton/index';
import Nube from '../../images/web_micrositio_Observatorios_iconos-_04-NUBE.png';
import Ver from '../../images/elementos_ver.png';

const Index = () => { 
  let [mainHeight, setMainHeight] = useState()
  useEffect(() => {
    const mainElement = document.getElementById('height_view');
    
    if (mainElement) {
      setMainHeight(mainElement.clientHeight + 110);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // ajusta la posición del scroll a la parte superior de la página
  }, []);

  return (
    <>
        <Header height={mainHeight} />
          <main className='main--solapas padding' id="height_view">
            <h1 id="height_view__h1">Vínculos responsables y diversos</h1>
            <Paragraph
              text="Te invitamos a descargar 
              materiales para pensar los vínculos afectivos desde una perspectiva de género, diversidad e 
              interculturalidad y promover la construcción de relaciones responsables e igualitarias."
            />
              <div className='contenedor-buttons' id="width-view">
                <h3>Folletos y Afiches</h3>
                  <Buttons  
                    img src={Nube}
                    texto="Folleto Noches consentidas"
                    href ="/downloads/vinculosResponsables/Folleto-Noches_consentidas.pdf"
                  /> 
                  <Buttons  
                    img src={Nube}
                    texto="Afiche Noches consentidas"
                    href ="/downloads/vinculosResponsables/Afiche_Noches_consentidas .pdf"
                  /> 
                  <h3>Materiales Audiovisuales</h3>
                  <VideoButton 
                    href="https://www.youtube.com/watch?v=0MSTU7mpfrs&list=PLdErRfhsb4srODnKnaqIAhYAr51nJXjgu&index=13"
                    img src={Ver}
                    texto="Hablemos de vínculos con Juventudes"
                  /> 
                    <h3>Materiales culturales</h3>
                  <Buttons  
                    img src={Nube}
                    texto="Taller de canciones: Qué cantamos cuando cantamos"
                    href ="/downloads/vinculosResponsables/Nosotrxs hacemos_ Taller de canciones. Qué cantamos cuando cantamos.pdf"
                  /> 
                  <Buttons  
                    img src={Nube}
                    texto="Taller de fanzine: Desarmar mitos"
                    href ="/downloads/vinculosResponsables/Nosotrxs hacemos_ Taller de fanzine_ Desarmar mitos..pdf"
                  /> 
                  <Buttons  
                    img src={Nube}
                    texto="Taller de construcción de afiche: Pensar el amor"
                    href ="/downloads/vinculosResponsables/Nosotrxs_hacemos_Taller_de_construccion_de_afiche_ Pensar_el_amor..pdf"
                  />  
                  <VideoButton
                    href="https://identidades.cultura.gob.ar/2022/09/06/concurso-federal-canciones-por-una-argentina-sin-violencias-de-genero/"
                    img src={Ver}
                    texto="Cancionero Federal: Canciones por una Argentina sin Violencias de Género"
                  />
                  <VideoButton 
                    href = "/avozalzada"
                    img src={Ver}
                    texto="Ilustraciones a Voz Alzada"
                    />
              </div>
              <hr className="mi-linea-horizontal"/>
          </main>
            <Footer/>
    </>
  )
}

export default Index