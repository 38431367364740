import React from 'react';
/*import Encabezado from '../../images/230602_micrositio_Campaña contra las violencias de genero_ elementos_header-01.svg'; */
import '../../styles.css';


const ImageGrid = ( { images } ) => {
  const handleDownload = (imageUrl) => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'imagen_descargable.jpg'; // Puedes cambiar el nombre del archivo aquí
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

    return (
      <div className="image-grid-container">
        <div className='image-grid-items'>
        {images.comprimidasVozalzada.map((image, index) => (
            <div key={index} className="image-grid-item" download>
              <img src={image} alt={`Imagen ${index}`} />
              
              <button className="buttons" onClick={() => handleDownload(images.avozalzada[index])}>Descargar</button>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default ImageGrid;
