import React, { useRef, useState, useEffect } from 'react';
import VerticalMenuMobil from '../../components/vertical-menu-mobile/index';
import ImageGrid from '../../components/image-grid/index';
import Footer from '../../components/footer/index';
import Mobil from '../../images/mobile-capitalHumano.svg';
import Header from '../../components/header/index';
/*import Encabezado from '../../images/Recurso 1.svg'; */

const Index = ({titulo, images}) => {
    let [mainHeight, setMainHeight] = useState()
    useEffect(() => {
        setTimeout(() => {
            const mainElement = document.getElementById('height_view');
        
            if (mainElement) {
            setMainHeight(mainElement.clientHeight + 205);
            }
            console.log('El useEffect se ejecutó después de 3 segundos');
            }, 5000);
        
        }, []); 
    

    return (
        <>  
            <Header height={mainHeight}/>
            <div className='title-aVozAlzada' >
                    <h1 className='encodesans-bold'>
                        {titulo}
                    </h1>
            </div>
            <main className='main--aVozAlzada' id="height_view" >
                <p className='encodesans-semiBold'>
                    Te invitamos a descargar, circular y compartir estas ilustraciones realizadas junto al Ministerio
                    de Cultura con historietistas, ilustradores e ilustradoras de distintos puntos del país.
                    “A voz Alzada” es una colección de imágenes que propone
                    reflexionar y forjar genealogías a través de la mirada y el trazo de artistas que construyen de 
                    forma coral postales de un país federal, y expresa los deseos de muchos y muchas de 
                    vivir vidas más igualitarias y libres de violencias, con perspectiva de género y derechos.
                </p>   
                <ImageGrid images={images} />
            </main> 
            <div id='footer_viewprincipal'>
                <Footer/>
            </div>
            <div id='footer_viewpimagenes'>
                <img src={Mobil} alt="footers"/>
            </div>
        </>
    );
    };


export default Index;