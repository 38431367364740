import React from 'react';
import { NavLink } from 'react-router-dom';
import '../../styles.css';



    const IndexMenuDesktop = () => {
        const activePage = window.location.pathname;
        const navLinks = document.querySelectorAll('nav-menu--horizontal a-link-nav-menu--horizontal').
        forEach(link => {
            console.log(link.href)
        })
        return (
            <>
                <nav className='nav-menu--horizontal' id='nav-menu--horizontal'>
                    <NavLink  className='a-link-nav-menu--horizontal  encodesans-semiBold' to={'/Identificaciondelasviolencias'}>Identificación de las violencias de género</NavLink >
                    <NavLink  className='a-link-nav-menu--horizontal  encodesans-semiBold' to={'/Estereotiposdegenero'} >Estereotipos de género</NavLink >
                    <NavLink  className='a-link-nav-menu--horizontal  encodesans-semiBold' to={'/Vinculosresponsablesydiversos'} >Vínculos responsables y diversos</NavLink >
                    <NavLink  className='a-link-nav-menu--horizontal  encodesans-semiBold' to={'/Masculinidadessinviolecias'}>Masculinidades sin violencias</NavLink >   
                    <NavLink  className='a-link-nav-menu--horizontal  encodesans-semiBold' to={'/Educacionsexualintegral'}> Educación Sexual Integral (ESI)</NavLink >
                </nav>
            </>
        )
    }

export default IndexMenuDesktop
