import React from 'react';
import { Link } from 'react-router-dom';
import ImgFooter from '../../images/footer_destok_logo-blue.svg';


const index = () => {
  return (
    <div>
      <Link to="https://www.argentina.gob.ar/generos"> 
        <img className="img-footer-desktop" src={ImgFooter}/>
      </Link>
    </div>
  )
}

export default index