import React from 'react';
import NavMobile from '../../components/nav-mobile/index';


const menuMobile = () => {
  return (
    <>
        <section className='header--mobile'>
            <NavMobile
                ruta="/Identificaciondelasviolencias"
                titulo='Identificación de las violencias de género'
                texto='Te invitamos a descargar herramientas de sensibilización para identificar 
                        las violencias de género y detectar sus manifestaciones menos visibles.'
            />
            <NavMobile
                ruta="/Estereotiposdegenero"
                titulo='Estereotipos de género'
                texto='Te invitamos a descargar materiales para reflexionar sobre los roles 
                y estereotipos de género que están presentes en diversos ámbitos de nuestras vidas.'
            />
            <NavMobile
                ruta="/Vinculosresponsablesydiversos"
                titulo='Vínculos responsables y diversos'
                texto='Te invitamos a descargar materiales para pensar los vínculos afectivos
                desde una perspectiva de género, diversidad e interculturalidad y promover
                la construcción de relaciones responsables e igualitarias.'
            />
            <NavMobile
                ruta="/Masculinidadessinviolecias"
                titulo='Masculinidades sin violencias'
                texto='Te invitamos a descargar herramientas para reflexionar y trabajar sobre
                las masculinidades desde la perspectiva de género y diversidad, con el objetivo
                de desnaturalizar los mandatos y estereotipos asignados a los varones que 
                reproducen las violencias y desigualdades.'
            />
                <NavMobile
                ruta="/Educacionsexualintegral"
                titulo='Educación Sexual Integral (ESI)'
                texto='Te invitamos a descargar materiales y herramientas para promover 
                la perspectiva de género y diversidad en las trayectorias educativas.'
            />
        </section>
    </>
  )
}

export default menuMobile;