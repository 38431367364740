import React from 'react';
import '../../styles.css';


const ImageGridSoymora = ( { images } ) => {
  const handleDownload = (imageUrl) => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'imagen_descargable.jpg'; // Puedes cambiar el nombre del archivo aquí
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

    return (
      <div className="image-grid-container">
        <div className='image-grid-items'>
        {images.comprimidasSoymora.map((image, index) => (
            <div key={index} className="image-grid-item" download>
              <img src={image} alt={`Imagen ${index}`} />
              <button className="buttons" onClick={() => handleDownload(images.soymora[index])}>Descargar</button>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default ImageGridSoymora;
