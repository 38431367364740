import React, {useEffect, useState} from 'react';
import Header from '../../components/header/index';
import Footer from '../../components/footer/index';
import Buttons from '../../components/buttons/index';
import Nube from '../../images/web_micrositio_Observatorios_iconos-_04-NUBE.png';
import Title from '../../components/titles/index';

const Index = () => {

    let [mainHeight, setMainHeight] = useState()
    useEffect(() => {
      setTimeout(() => {
        const mainElement = document.getElementById('height_view');
      
        if (mainElement) {
          setMainHeight(mainElement.clientHeight + 210);
        }
        console.log('El useEffect se ejecutó después de 3 segundos');
      }, 2000);
      
    }, []); 
      useEffect(() => {
        window.scrollTo(0, 0); // ajusta la posición del scroll a la parte superior de la página
      }, []);
    return (
    <>
      <Header height={mainHeight}/>
        <div className='title-aVozAlzada'>
            <h1 className='encodesans-bold'>
            <Title titulo="Niñeces"/>
            </h1> 
        </div>
        <main className='main--solapas_custumizado' id="height_view">
        <section className='padding'>
        <div className='contenedor-buttons'>
            <Buttons  
              img src={Nube}
              texto='Ilustraciones para colorear: Campeones del futuro'
              href="/downloads/esi/Ilustraciones-para_colorear_Campeonxs_del_futuro_(1).pdf"
              /> 
            <Buttons  
              img src={Nube}
              texto='Sopas de letras para infancias'
              href ="/downloads/esi/Sopa_de_Letras_para_Infancias.pdf"
            /> 
            <Buttons  
              img src={Nube}
              texto='Rayuela por la Igualdad'
              href ="/downloads/esi/Rayuela_ Vínculos responsables y diversos.pdf"
            /> 
            <Buttons  
              img src={Nube}
              texto='Pescando ideas'
              href = "/downloads/esi/Pescando Ideas.pdf"
            /> 
        </div>
        <hr className="mi-linea-horizontal"/>
        </section>
      </main>
      <div>
        <Footer/>
      </div>
    </>
  )
}

export default Index