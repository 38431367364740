import React, {useEffect, useState} from 'react';
import Header from '../../components/header/index';
import Footer from '../../components/footer/index';
import Buttons from '../../components/buttons/index';
import Nube from '../../images/web_micrositio_Observatorios_iconos-_04-NUBE.png';
import Title from '../../components/titles/index';

const Index = () => {

  let [mainHeight, setMainHeight] = useState()
  useEffect(() => {
    setTimeout(() => {
      const mainElement = document.getElementById('height_view');
    
      if (mainElement) {
        setMainHeight(mainElement.clientHeight + 210);
      }
      console.log('El useEffect se ejecutó después de 3 segundos');
    }, 2000);
    
  }, []); 
    useEffect(() => {
      window.scrollTo(0, 0); // ajusta la posición del scroll a la parte superior de la página
    }, []);

    return (
    <>
        <Header height={mainHeight}/>
            <div className='title-aVozAlzada'>
                <h1 className='encodesans-bold'>
                <Title titulo="Espacios culturales y comunitarios"/>
                </h1> 
            </div>
            <main className='main--solapas_custumizado' id="height_view">
            <section className='padding'>
              <div className='contenedor-buttons' id="width-view">
                  <Buttons  
                    img src={Nube}
                    texto='Armado de gigantografía de papel: Intervención colectiva para el 25N'
                    href="/downloads/identificacionViolenciasDeGenero/Mural_de_papel_ Intervencion_colectiva_para_el_25N_(1).pdf"
                    /> 
                  <Buttons  
                    img src={Nube}
                    texto='Videos de estrategias de prevención de las violencias de género en proyectos y espacios culturales'
                    href ="https://www.youtube.com/playlist?list=PLNy1I05AL0fDRw4aNsvUNxVYlsd5rB7is"
                  /> 
                  <Buttons  
                    img src={Nube}
                    texto='Guía para la prevención de las violencias de género en proyectos y espacios culturales'
                    href ="/downloads/identificacionViolenciasDeGenero/Guia_de_introduccion_para_la_prevencion _de_las_violencias_de_genero_en_proyectos_y_espacios_culturales.pdf"
                    />
              </div>
              <hr className="mi-linea-horizontal"/>
              </section>
          </main>
          <div>
            <Footer/>
          </div>
    </>
  )
}

export default Index