import React, {useEffect, useState} from 'react';
import Header from '../../components/header/index';
import Footer from '../../components/footer/index';
import Buttons from '../../components/buttons/index';
import Nube from '../../images/web_micrositio_Observatorios_iconos-_04-NUBE.png';
import Title from '../../components/titles/index';

const Index = () => {

  let [mainHeight, setMainHeight] = useState()

  useEffect(() => {
    setTimeout(() => {
      const mainElement = document.getElementById('height_view');
    
      if (mainElement) {
        setMainHeight(mainElement.clientHeight + 210);
      }
      console.log('El useEffect se ejecutó después de 3 segundos');
    }, 2000);
    
  }, []); 
    useEffect(() => {
      window.scrollTo(0, 0); // ajusta la posición del scroll a la parte superior de la página
    }, []);

    return (
    <>
        <Header height={mainHeight}/>
            <div className='title-aVozAlzada'>
                <h1 className='encodesans-bold'>
                <Title titulo="Juventudes"/>
                </h1> 
            </div>
            <main className='main--solapas' id="height_view">
            <section className='padding'> 
              <div className='contenedor-buttons' id="width-view">
                  <Buttons  
                    img src={Nube}
                    texto="Guía para la prevención de las Violencias de género en entornos digitales"
                    href="/downloads/esi/231030-Guía para la prevención de las violencias de género en entornos digitales-v7.pdf"
                    /> 
                  <Buttons  
                    img src={Nube}
                    texto="Kit de talleres para armar y desarmar: Nosotros hacemos"
                    href="/downloads/esi/Kit de talleres para armar y desarmar “Nosotrxs hacemos_.pdf"
                    /> 
                  <Buttons  
                    img src={Nube}
                    texto="Taller: Masculinidades para armar"
                    href ="/downloads/masculinidades/Taller _Masculinidades_para_armar_.pdf"
                  /> 
                  <Buttons  
                    img src={Nube}
                    texto='Taller de podcast: La igualdad es mundial'
                    href ="/downloads/estereotiposGenero/Taller-de_podcast-la_igualdad_es_mundial.pdf"
                  /> 
                  <Buttons  
                    img src={Nube}
                    texto='Ideas al cubo: Glosario por una Argentina sin violencias '
                    href = "/downloads/estereotiposGenero/Ideas-al_Cubo_ Glosario_por_una_Argentina_sin_Violencias.pdf"
                  /> 
                  <Buttons  
                    img src={Nube}
                    texto='Mazos de cartas por la igualdad'
                    href ="/downloads/esi/Mazo de cartas por la igualdad.pdf"
                  /> 
                  <Buttons  
                    img src={Nube}
                    texto='Crucigrama para la igualdad: cruzando ideas'
                    href ="/downloads/esi/Crucigrama_para_la_Igualdad_Cruzando_Ideas.pdf"
                  />
                  <Buttons  
                    img src={Nube}
                    texto='Sopa de letras para las juventudes'
                    href = "/downloads/esi/Sopa de letras para Juventudes.pdf"
                  /> 
                    <Buttons  
                    img src={Nube}
                    texto='Ruleta Dale la Vuelta'
                    href = "/downloads/esi/Ruleta Dale la Vuelta.pdf"
                  /> 
              </div>
              <hr className="mi-linea-horizontal"/>
              </section>
          </main>
          <div>
            <Footer/>
          </div>
    </>
  )
}

export default Index