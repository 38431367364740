import React, { useRef } from 'react';
import '../../styles.css';
import HorizontalMenuDesktop from '../../components/horizontal-menu-desktop/index';
import VerticalDrownMenu from '../vertical-drownmenu-desktop/index';
import VerticalMenuMobil from '../vertical-menu-mobile/index';
import Encabezado from '../../images/Recurso 1.svg';

const Index = ({ height }) => {
    const handleGoToHome = () => {
        window.location.href = '/'; // Cambia esta URL según la ruta de tu home
    };

        return (
        <>
            <div id='header--destkop__div'>
                <a href='/' id='header--destkop'>
                    <img className="imgEncabezado" id="imgEncabezado" src={Encabezado} alt="Imagen del encabezado" />
                </a>
                <div className='conteiner-menus-desktop'>
                    <HorizontalMenuDesktop/>
                    <VerticalDrownMenu height={height} id='VerticalDrownMenu'/>
                </div>
            </div>
            <div>
                <VerticalMenuMobil/>
            </div>
        </>
    ); 
}

export default Index
