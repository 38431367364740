
import  Home from "./pages/home/index.jsx";

import Identificaciondelasviolencias from "./pages/identificacion-de-las-violencias/index.jsx";
import Estereotiposdegenero from './pages/estereotipos-de-genero/index.jsx';
import Vinculosresponsablesydiversos from './pages/vinculos-responsables-y-diversos/index.jsx';
import Masculinidadessinviolecias from './pages/masculinidades-sin-violencias/index.jsx';
import Educacionsexualintegral from './pages/educacion-sexual-integral/index.jsx';
import TemplateAvozAlzada from './pages/a-voz-alzada/index.jsx';
import TemplateSoyMora from './pages/soy-mora/index.jsx';
import Juventudes from './pages/juventudes/index.jsx';
import Nineces from './pages/nineces/index.jsx';
import Promotores from './pages/promotores/index.jsx';
import Espacios from './pages/espacios/index.jsx';

import './styles.css';
import avosalzada from './services/avosalzada.jsx';
import avosalzada_soymora from './services/avosalzada_soymora';
import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} /> 
        <Route path="/identificaciondelasviolencias" element={<Identificaciondelasviolencias/>} />
        <Route path="/estereotiposdegenero" element={<Estereotiposdegenero/>} />
        <Route path="/vinculosresponsablesydiversos" element={<Vinculosresponsablesydiversos/>} />
        <Route path="/masculinidadessinviolecias" element={<Masculinidadessinviolecias/>} />
        <Route path="/educacionsexualintegral" element={<Educacionsexualintegral />} />
        <Route path="/juventudes" element={<Juventudes/>} />
        <Route path="/nineces" element={<Nineces/>}/> 
        <Route path="/promotoresterritoriales" element={ <Promotores/> } />  
        <Route path="/espaciosculturales" element={ < Espacios /> } />
        <Route 
          path="/avozalzada" element={
          <TemplateAvozAlzada 
            titulo="identificación de las violencias de género / Ilustraciones a Voz alzada sobre la violencia"
            images={avosalzada} 
          />
        } />
        <Route 
          path="/soymora" 
          element={
            <TemplateSoyMora 
              titulo="Ilustraciones a Voz alzada sobre la Violencia Política  por @soymora"
              images={avosalzada_soymora}
            />
          } 
        /> 
      </Routes>
    </BrowserRouter>
  );
}

export default App;
