import React from 'react';
import { Link } from 'react-router-dom';

export const NavMobile = ( { titulo, texto, ruta }) => {
  return (
    <div className='conteiner--nav__mobile'>
        <h3 className='title--mobile'><Link className='title--mobile_link' to={ruta}>{titulo}</Link></h3>
        <p className='paragraph--mobile   EncodeSans-SemiBold'>{texto}</p>
    </div>
  )
}

export default NavMobile;