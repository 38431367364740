const comprimidasSoymora = [
    require("../images/comprimidas_soymora/Ilustración Asamblea por Soy Mora (1).jpg"),
    require("../images/comprimidas_soymora/Ilustración Sindicato por Soy Mora (1).jpg"),
    require("../images/comprimidas_soymora/Ilustración Violencia Digital por Soy Mora (1).jpg"),
    require("../images/comprimidas_soymora/Ilustración Movilización por Soy Mora (1).jpg"),
];


const soymora = [
    "/downloads/aVozAlzadaSoyMora/Ilustracion_Asamblea_por_Soy_Mora.jpg",
    "/downloads/aVozAlzadaSoyMora/Ilustracion _Sindicato_por_Soy_Mora.jpg",
    "/downloads/aVozAlzadaSoyMora/Ilustracion _Violencia_Digital_por_Soy_Mora.jpg",
    "/downloads/aVozAlzadaSoyMora/Ilustración Movilización por Soy Mora.jpg",
];

export default {comprimidasSoymora, soymora} ;