import React , {useState} from 'react';
import '../../styles.css';
import VerticalDrownmenuDesktop from '../vertical-drownmenu-desktop/index';
import ImagenEncabezaadoMobil from '../../images/elementos_movil-v1_header-color-22-svg.svg';


const IndexMenuMobile = () => {
    const [menu, setMenu] = useState(false);

    const toggleMenu = () => {
        setMenu(!menu);
    }
        return (
        <>
        <section id="nav-vertical-mobile" className='section-menu-mobile'>
            <button className="toggleMenu--Mobile"  onClick={toggleMenu}>
                <img src={ImagenEncabezaadoMobil} alt="Menú Mobile" />
            </button>

            <div className={`container-menu-mobile list__hide ${menu ? 'isActive' : ''}`}>
                <VerticalDrownmenuDesktop className="container-a-mobile" />
            </div>
        </section>
        </>
    )
} 
export default  IndexMenuMobile 

