import React, {useState, useEffect} from 'react';
import Header from '../../components/header/index';
import Footer from '../../components/footer/index';
import VideoButton from '../../components/videoButton/index';
import Buttons from '../../components/buttons/index';
import Nube from '../../images/web_micrositio_Observatorios_iconos-_04-NUBE.png';
import Paragraph from '../../components/paragraph/index';
import Ver from '../../images/elementos_ver.png';

const Index = () => {

  let [mainHeight, setMainHeight] = useState()
  useEffect(() => {
    const mainElement = document.getElementById('height_view');
    
    if (mainElement) {
      setMainHeight(mainElement.clientHeight + 140);

    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // ajusta la posición del scroll a la parte superior de la página
  }, []);
  
  return (
    <>
    <div className="info-container">
        <Header height={mainHeight}/>
          <main className='main--solapas padding' id="height_view">   
            <h1 id="height_view__h1">Masculinidades sin violencias</h1>
              <Paragraph
                  text="Te invitamos a descargar herramientas para reflexionar y trabajar sobre las masculinidades desde la 
                  perspectiva de género y diversidad, con el objetivo de desnaturalizar los mandatos y estereotipos 
                  asignados a los varones que reproducen las violencias y desigualdades."
                  />
              <div className='contenedor-buttons' id="width-view">
                <h3>Guías y herramientas para la capacitación</h3>
                  <Buttons  
                    img src={Nube}
                    texto="Guía de Masculinidades sin violencias"
                    href ="/downloads/masculinidades/Guia_de_Masculinidades_sin_violencias.pdf"
                  /> 
                  <h3>Mapa Federal de Experiencias con Varones y Masculinidades (MEVyM)</h3>
                  <Paragraph
                  text="Podés encontrar información sobre experiencias del sector 
                  público, organizaciones que trabajan con varones y masculinidades, espacios de
                  atención a varones que han ejercido violencia, materiales y recursos que abordan estas temáticas."
                  />
                  <VideoButton 
                    href ="https://mevym.mingeneros.gob.ar/"
                    img src={Ver}
                    texto="MEVyM"
                  /> 
                  <h3>Folletos y Afiches</h3>
                  <Buttons  
                    img src={Nube}
                    texto="Afiche ¿Qué podemos hacer los varones?"
                    href ="/downloads/masculinidades/Afiche ¿Qué podemos hacer los varones_.pdf"
                  /> 
                  <Buttons  
                    img src={Nube}
                    texto="Folleto ¿Qué podemos hacer los varones?"
                    href ="/downloads/masculinidades/Folleto ¿Qué podemos hacer los varones_.pdf"
                  /> 
                  <h3>Materiales Audiovisuales</h3>
                  <VideoButton 
                    href ="https://www.youtube.com/watch?v=GTphH3h_d-A&list=PLdErRfhsb4srODnKnaqIAhYAr51nJXjgu&index=14&ab_channel=MinisteriodelasMujeres%2CG%C3%A9nerosyDiversidad"
                    img src={Ver}
                    texto="Hablemos de masculinidades con juventudes"
                  /> 
                  <h3>Materiales culturales</h3> 
                  <Buttons  
                    img src={Nube}
                    texto="Taller: Masculinidades para armar"
                    href ="/downloads/masculinidades/Taller _Masculinidades_para_armar_.pdf"
                  /> 
              </div>
              <hr className="mi-linea-horizontal"/>
          </main>
          </div>
          <div>
            <Footer/>
          </div>
    </>
  )
}

export default Index