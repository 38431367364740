const comprimidasVozalzada = [
    require("../images/comprimidas_avozalzada/Acevedo_Kortsarz.jpg"),
    "/downloads/aVozAlzada/Ilustracion_de_1.2_Bietka.png",
    require("../images/comprimidas_avozalzada/Ilustración de Claudia Salazar.jpeg"),
    require("../images/comprimidas_avozalzada/Ilustración de Flo Meije.jpg"),
    "/downloads/aVozAlzada/Ilustracion_de_Jules.jpg",
    require("../images/comprimidas_avozalzada/Ilustración de Eleonora Kortsarz (1).jpg"),
    "/downloads/aVozAlzada/Ilustracion_de_La_lejana.jpg",
    require("../images/comprimidas_avozalzada/Ilustración de La Lejana_1.jpg"),
    require("../images/comprimidas_avozalzada/Ilutración por Florencia Vallejos.jpg"),
    require("../images/comprimidas_avozalzada/Ilustración de Trewelina (1).png"),
    require("../images/comprimidas_avozalzada/Ilustración de Maia Debowicz.jpg"),
    require("../images/comprimidas_avozalzada/Ilustración de Muriel Frega (1).jpg"),
    require("../images/comprimidas_avozalzada/Ilustración de María Fernanda Zuccón (1).jpg"),
    require("../images/comprimidas_avozalzada/Ilustración de Querida Guachita (1).jpg"),
    require("../images/comprimidas_avozalzada/Ilustración de Delia Montaña.jpg"),
    require("../images/comprimidas_avozalzada/Ilustración de Charlie de la V..jpg"),
];

const avozalzada = [
    "/downloads/aVozAlzada/Acevedo_Kortsarz_1.jpg",
    "/downloads/aVozAlzada/Ilustracion_de_1.2_Bietka.png",
    "/downloads/aVozAlzada/Ilustracion_de_Claudia_Salazar.jpeg",
    "/downloads/aVozAlzada/Ilustracion_de_Flo_Meije.jpg",
    "/downloads/aVozAlzada/Ilustracion_de_Jules.jpg",
    "/downloads/aVozAlzada/Ilustración de Eleonora Kortsarz.jpg",
    "/downloads/aVozAlzada/Ilustracion_de_La_lejana.jpg",
    "/downloads/aVozAlzada/Ilustracion_de_la_lejana_1.jpg",
    "/downloads/aVozAlzada/Ilutracion_por_Florencia_Vallejos.jpg",
    "/downloads/aVozAlzada/Ilustracion_de_Trewelina.png",
    "/downloads/aVozAlzada/Ilustracion_de_Maia_Debowicz.jpg",
    "/downloads/aVozAlzada/Ilustracion_de_Muriel_Frega.jpg",
    "/downloads/aVozAlzada/Ilustracion_de_María_Fernanda_Zuccón.jpg",
    "/downloads/aVozAlzada/Ilustracion_de_Querida_Guachita.jpg",
    "/downloads/aVozAlzada/Ilustracion_de_Delia_Montana.jpg",
    "/downloads/aVozAlzada/Ilustracion_de_Charlie_de_la_v.jpg",
];


    
export default {  comprimidasVozalzada, avozalzada }