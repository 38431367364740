import React, { useEffect, useState} from 'react';
import Header from '../../components/header/index';
import Footer from '../../components/footer/index';
import Paragraph from '../../components/paragraph/index';
import Buttons from '../../components/buttons/index';
import VideoButton from '../../components/videoButton/index';
import Nube from '../../images/web_micrositio_Observatorios_iconos-_04-NUBE.png';
import Ver from '../../images/elementos_ver.png';

const Index = () => {
  let [mainHeight, setMainHeight] = useState()

  useEffect(() => {
    const mainElement = document.getElementById('height_view');
    
    if (mainElement) {
      setMainHeight(mainElement.clientHeight + 100);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // ajusta la posición del scroll a la parte superior de la página
  }, []);

  return (
    <>
        <Header height={mainHeight}/>
          <main className='main--solapas padding' id="height_view">
            <h1 id="height_view__h1">Estereotipos de género</h1>
            <Paragraph
              text="Te invitamos a descargar materiales para reflexionar sobre los roles y estereotipos de género 
              que están presentes en diversos ámbitos de nuestras vidas."
            />
              <div className='contenedor-buttons' id="width-view">
                  <h3>Folletos y Afiches</h3>
                  <Buttons  
                    img src={Nube}
                    texto="Estereotipos de género"
                    href ="/downloads/estereotiposGenero/Folleto_sobre_estereotipos_de_genero.pdf"
                  /> 
                  <h3>Materiales Audiovisuales</h3>
                  <VideoButton 
                    href="https://www.youtube.com/watch?v=xCtYPUhdAC8&list=PLdErRfhsb4srODnKnaqIAhYAr51nJXjgu&index=11&ab_channel=MinisteriodelasMujeres%2CG%C3%A9nerosyDiversidad"
                    img src={Ver}
                    texto="Hablemos de estereotipos con Juventudes"
                  /> 
                  <h3>Materiales Culturales</h3>
                  <Buttons  
                    img src={Nube}
                    texto="Taller de fotografía: Desarmar roles"
                    href ="/downloads/estereotiposGenero/Nosotrxs hacemos_ Taller de fotografía_ Desarmar roles .pdf"
                  /> 
                  <Buttons  
                    img src={Nube}
                    texto="Taller de podcast: Apagar mandatos"
                    href ="/downloads/estereotiposGenero/Nosotrxs hacemos_ Taller de podcast_ Apagar mandatos.pdf"
                  /> 
                  <Buttons  
                    img src={Nube}
                    texto="Taller de podcast: La Igualdad es Mundial"
                    href ="/downloads/estereotiposGenero/Taller-de_podcast-la_igualdad_es_mundial.pdf"
                  /> 
                  <Buttons  
                    img src={Nube}
                    texto="Ideas al Cubo: Glosario por una Argentina sin Violencias"
                    href ="/downloads/estereotiposGenero/Ideas-al_Cubo_ Glosario_por_una_Argentina_sin_Violencias.pdf"
                  /> 
              </div>
              <hr className="mi-linea-horizontal"/>
          </main>
          <div>
            <Footer/>
          </div>
    </>
  )
}

export default Index