import React from 'react';

export const paragraph = ( props ) => {
  return (
    <p className="paragraph-genero encodesans-semiBold"> 
        {props.text}
    </p>
  )
}

export default paragraph;