import Principal from '../principal/index';

function home() {
    return (
        <>
        <Principal/>
        </>
    );
}

export default home;
