import React, {useState, useEffect} from 'react';
import Header from '../../components/header/index';
import Footer from '../../components/footer/index';
import Paragraph from '../../components/paragraph/index';
import VideoButton from '../../components/videoButton/index';
import Buttons from '../../components/buttons/index';
import Nube from '../../images/web_micrositio_Observatorios_iconos-_04-NUBE.png';
import Ver from '../../images/elementos_ver.png';


const Index = () => {
  let [mainHeight, setMainHeight] = useState()
  useEffect(() => {
    const mainElement = document.getElementById('height_view');
    
    if (mainElement) {
      setMainHeight(mainElement.clientHeight + 120);
    }
  }, []);
  
  useEffect(() => {
    window.scrollTo(0, 0); // ajusta la posición del scroll a la parte superior de la página
  }, []);

  return (
    <>
        <Header height={mainHeight}/>
          <main className='main--solapas padding' id="height_view">
            <h1 id="height_view__h1">Educación Sexual Integral (ESI) </h1>
            <Paragraph
              text="Te invitamos a descargar materiales y herramientas para
              promover la perspectiva de género y diversidad e incluir actividades de 
              ESI en espacios de educación formal y/o comunitarios (deportivos, recreativos, culturales, etc)."
            />
              <div className='contenedor-buttons' id="width-view">
              <h3>Materiales audiovisuales</h3>
                  <VideoButton 
                    href="https://www.educ.ar/recursos/158076/ejercer-derechos-repensar-vinculos-aportes-en-clave-de-gener"
                    img src={Ver}
                    texto="Colección: Ejercer derechos, repensar vínculos "
                  />   
                  <hr className="mi-linea-horizontal"/>
                  <h3 className='encodesans-semiBold' >Juegos</h3>
                  <Buttons  
                    img src={Nube}
                    texto="Mazo de cartas por la igualdad"
                    href="/downloads/esi/Mazo de cartas por la igualdad.pdf"
                  /> 
                  <Buttons  
                    img src={Nube}
                    texto="Crucigrama para la igualdad: Cruzando Ideas"
                    href ="/downloads/esi/Crucigrama_para_la_Igualdad_Cruzando_Ideas.pdf"
                  /> 
                  <Buttons  
                    img src={Nube}
                    texto="Sopa de Letras para Infancias"
                    href ="/downloads/esi/Sopa_de_Letras_para_Infancias.pdf"
                  /> 
                  <Buttons  
                    img src={Nube}
                    texto="Sopa de letras para juventudes"
                    href ="/downloads/esi/Sopa de letras para Juventudes.pdf"
                  /> 
                  <Buttons  
                    img src={Nube}
                    texto="Ruleta Dale la Vuelta"
                    href ="/downloads/esi/Ruleta Dale la Vuelta.pdf"
                  /> 
                  <Buttons  
                    img src={Nube}
                    texto="Rayuela: Vínculos responsables y diversos"
                    href ="/downloads/esi/Rayuela_ Vínculos responsables y diversos.pdf"
                  /> 
                  <Buttons  
                    img src={Nube}
                    texto="Pescando ideas "
                    href ="/downloads/esi/Pescando Ideas.pdf"
                  /> 
                  <Buttons  
                    img src={Nube}
                    texto="Ilustraciones para colorear: Campeones del futuro "
                    href ="/downloads/esi/Ilustraciones-para_colorear_Campeonxs_del_futuro_(1).pdf"
                  /> 
              </div>
              <hr className="mi-linea-horizontal"/>
          </main>
          <div>
            <Footer/>
          </div>
    </>
  )
}

export default Index