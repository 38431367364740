import React, { useRef, useEffect, useState } from 'react';
import VideoSpot from '../../components/video/Campaña Violencias_SPOT.mp4'
import Header from '../../components/header/index';
import Carousel from '../../components/carousel/index';
import HorizontalMenuMobilePrincipal from '../../components/horizontal-menu-mobile-principal/index';


import Icono from '../../images/elementos_movil_v1_intro_06.png';
import Mobil from '../../images/mobile-capitalHumano.svg';
import Footer from '../../components/footer/index';

const Index = () => {

  let [mainHeight, setMainHeight] = useState()

  useEffect(() => {
    setTimeout(() => {
      const mainElement = document.getElementById('height_view');
    
      if (mainElement) {
        setMainHeight(mainElement.clientHeight + 80);
      }
      console.log('El useEffect se ejecutó después de 3 segundos');
    }, 2000);
    
  }, []); 
  
      return (
        <>
          <Header height={mainHeight}/>
            <main className='HomeBody' id="height_view">
              <p className='paragraph-principal EncodeSans-SemiBold'>
              <b>La Campaña Nacional de Prevención de las Violencias de Género</b> se propone trabajar
              sobre el origen de las violencias: las desigualdades de género. Se trata de una invitación
              a la reflexión, a la acción y a la construcción colectiva para (re)pensar las prácticas y
              conductas que muchas veces naturalizamos sin darnos cuenta y que reproducen las
              desigualdades y las violencias de género.
              </p>
              <p className='paragraph-second EncodeSans-SemiBold'>
              La Campaña promueve la reflexión, el cuestionamiento y la problematización de los
              sentidos comunes y discursos que sustentan y reproducen los distintos tipos y
              modalidades de violencias de género, con el objetivo de construir una sociedad más justa
              e igualitaria.
              </p>
              <div className='video-container'>
                  <div className='video-wrapper'>
                    <video
                      className="responsive-video"
                      controls
                      src={VideoSpot}
                    />
                  </div>
              </div>        
                <Carousel/>           
      </main>
      <div id='footer_viewprincipal'>
          <Footer/>
      </div>
    
      <main>
        <article className='article--movil' id='article--movil'>
          <div className='div-paragraph__movil' id='div-paragraph__movil'>
            <img src={Icono} alt="fondo header"/>
            <p className='paragraph-movil EncodeSans-SemiBold'>
                <b>La Campaña Nacional de Prevención de las Violencias de Género</b> se propone trabajar
                sobre el origen de las violencias: las desigualdades de género. Se trata de una invitación
                a la reflexión, a la acción y a la construcción colectiva para (re)pensar las prácticas y
                conductas que muchas veces naturalizamos sin darnos cuenta y que reproducen las
                desigualdades y las violencias de género.
              </p>
              <p className='paragraph-movil EncodeSans-SemiBold'>
                La Campaña promueve la reflexión, el cuestionamiento y la problematización de los
                sentidos comunes y discursos que sustentan y reproducen los distintos tipos y
                modalidades de violencias de género, con el objetivo de construir una sociedad más justa
                e igualitaria.
              </p>
          </div>
          <div className='video-container'>
            <div className='video-wrapper'>
                <video
                  className="responsive-video"
                  controls
                  src={VideoSpot}
                />
            </div>
          </div> 
          <HorizontalMenuMobilePrincipal/>
          <div>
            <img src={Mobil} alt="footers"/>
          </div>
          </article>
        </main>
        </>
    );
  }

export default Index