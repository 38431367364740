
import Linea144 from '../../images/logo-linea144.svg';
import Acerca from '../../images/logo-acercar-derechos.svg';
import Programa from '../../images/programa-acompanar.svg';
import Articular from '../../images/programa-articular.svg';
import Escuela from '../../images/programa-escuelas-formacion.svg';
import Ciudadania from '../../images/micaela-ciudadania.svg';

function InfiniteSlider() {
  return (
    <div id="marquee-container">
      <div id='marquee-track'>
        <div className="marquee-content">
          <a href="https://www.argentina.gob.ar/generos/linea-144" target="_blank">
            <img src={Linea144} alt="imagen de la linea 144" className="img-carrousel"/>
          </a>
        </div>

        <div className="marquee-content">
          <a href="https://www.argentina.gob.ar/generos/programa-acercar-derechos" target="_blank">
            <img src={Acerca} alt="imagen de la linea 144" className="img-carrousel"/>
          </a>
        </div>

        <div className="marquee-content">
          <a href="https://www.argentina.gob.ar/generos/plan_nacional_de_accion_contra_las_violencias_por_motivos_de_genero/programa-acompanar" target="_blank">
            <img src={Programa} alt="imagen de la linea 144" className="img-carrousel"/>
          </a>
        </div>

        <div className="marquee-content">
          <a href="https://www.argentina.gob.ar/generos/plan_nacional_de_accion_contra_las_violencias_por_motivos_de_genero/programa-articular" target="_blank">
            <img src={Articular} alt="imagen de la linea 144" className="img-carrousel"/>
          </a>
        </div>

        <div className="marquee-content">
          <a href="https://www.argentina.gob.ar/generos/plan_nacional_de_accion_contra_las_violencias_por_motivos_de_genero/prog-escuelas-pop-formac-en-gen-y-diver" target="_blank">
            <img src={Escuela} alt="imagen de la linea 144" className="img-carrousel"/>
          </a>
        </div>

        <div className="marquee-content">
          <a href="https://www.argentina.gob.ar/generos/ley-micaela" target="_blank">
            <img src={Ciudadania} alt="imagen de la linea 144" className="img-carrousel"/>
          </a>
        </div>
      </div>
    </div>
  );
}

export default InfiniteSlider;
