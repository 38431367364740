import React, { useEffect, useState } from "react";
import Header from "../../components/header/index";
import Footer from "../../components/footer/index";
import Paragraph from "../../components/paragraph/index";
import Buttons from "../../components/buttons/index";
import VideoButton from "../../components/videoButton/index";
import Nube from "../../images/web_micrositio_Observatorios_iconos-_04-NUBE.png";
import Ver from "../../images/elementos_ver.png";

const Index = () => {
  let [mainHeight, setMainHeight] = useState();
  useEffect(() => {
    const mainElement = document.getElementById("height_view");

    if (mainElement) {
      setMainHeight(mainElement.clientHeight + 110);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // ajusta la posición del scroll a la parte superior de la página
  }, []);

  return (
    <>
      <Header height={mainHeight} />
      <main className="main--solapas padding" id="height_view">
        <h1 id="height_view__h1">Identificación de las violencias de género</h1>
        <Paragraph
          text="Te invitamos a descargar herramientas de sensibilización para identificar las violencias de 
                género y detectar sus manifestaciones menos visibles."
        />
        <div className="contenedor-buttons" id="width-view">
          <h3>Guías y herramientas para la capacitación</h3>
          <Buttons
            img
            src={Nube}
            texto="Herramientas para la detección temprana de señales de las violencias de género"
            href="/downloads/identificacionViolenciasDeGenero/Guia_de_herramientas_para_la_detección_de_señales_tempranas_de_las_violencias_por_motivos_de_genero.pdf"
          />
          <Buttons
            img
            src={Nube}
            texto="La Violencia Sexual es Violencia de Género"
            href="/downloads/identificacionViolenciasDeGenero/Guia_la_violencia_sexual_es_violencia_de_genero.pdf"
          />
          <Buttons
            img
            src={Nube}
            texto="Introducción para la prevención de las violencias de género en proyectos y espacios culturales"
            href="/downloads/identificacionViolenciasDeGenero/Guia_de_introduccion_para_la_prevencion _de_las_violencias_de_genero_en_proyectos_y_espacios_culturales.pdf"
          />
          <Buttons
            img
            src={Nube}
            texto="Guía para la prevención de las Violencias de género en entornos digitales"
            href="/downloads/esi/231030-Guía para la prevención de las violencias de género en entornos digitales-v7.pdf"
          />
          <h3>Folletos</h3>
          {/* Folleto*/}
          <Buttons
            img
            src={Nube}
            texto="Tipos y modalidades de violencias de género"
            href="/downloads/identificacionViolenciasDeGenero/Folleto_sobre_tipos_y_modalidades_de_violencias.pdf"
          />
          {/* Folleto*/}
          <Buttons
            img
            src={Nube}
            texto="Violencia Sexual "
            href="/downloads/identificacionViolenciasDeGenero/Folleto_sobre_violencia_sexual.pdf"
          />
          <h3>Materiales Audiovisuales</h3>
          <VideoButton
            href="https://www.youtube.com/watch?v=MfNcyAgrprQ&list=PLdErRfhsb4srODnKnaqIAhYAr51nJXjgu&index=9&ab_channel=MinisteriodelasMujeres%2CG%C3%A9nerosyDiversidad"
            img
            src={Ver}
            texto="Prevención de la Violencia de Género en Ámbitos Laborales "
          />
          <VideoButton
            href="https://www.youtube.com/watch?v=M46Lb2l5b04&list=PLdErRfhsb4srODnKnaqIAhYAr51nJXjgu&index=15&ab_channel=MinisteriodelasMujeres%2CG%C3%A9nerosyDiversidad"
            img
            src={Ver}
            texto="Hablemos de Violencia Política"
          />
          <VideoButton
            href="https://www.youtube.com/watch?v=q5Eh3Ey0zCY&list=PLdErRfhsb4srODnKnaqIAhYAr51nJXjgu&index=13&t=8s&ab_channel=MinisteriodelasMujeres%2CG%C3%A9nerosyDiversidad"
            img
            src={Ver}
            texto="Hablemos de violencias con juventudes "
          />

          <VideoButton
            href="https://www.youtube.com/watch?v=pcfVroBLPsI&list=PLdErRfhsb4srODnKnaqIAhYAr51nJXjgu&index=16&ab_channel=MinisteriodelasMujeres%2CG%C3%A9nerosyDiversidad"
            img
            src={Ver}
            texto="De promotor a promotor: Escucha Activa "
          />
          <VideoButton
            href="https://www.youtube.com/watch?v=GY_8bPDNq-4&list=PLdErRfhsb4srODnKnaqIAhYAr51nJXjgu&index=17&ab_channel=MinisteriodelasMujeres%2CG%C3%A9nerosyDiversidad"
            img
            src={Ver}
            texto="De promotor a promotor: Interseccionalidad"
          />
          <VideoButton
            href="https://www.youtube.com/watch?v=S6yLPbSjM6s&list=PLdErRfhsb4srODnKnaqIAhYAr51nJXjgu&index=2&ab_channel=MinisteriodelasMujeres%2CG%C3%A9nerosyDiversidad"
            img
            src={Ver}
            texto="Empecemos a pensarlo #ArgentinaSinViolencias"
          />
          <VideoButton
            href="https://www.youtube.com/playlist?list=PLNy1I05AL0fDRw4aNsvUNxVYlsd5rB7is"
            img
            src={Ver}
            texto="Estrategias de prevención de las violencias de género en proyectos y espacios culturales (Formar cultura)"
          />
          <h3>Materiales Culturales</h3>
          <Buttons
            img
            src={Nube}
            texto="Armado de gigantografía de papel: Intervención colectiva para el 3J"
            href="/downloads/identificacionViolenciasDeGenero/Mural de papel_ Intervención colectiva para el  3J.pdf"
          />
          <Buttons
            img
            src={Nube}
            texto="Armado de gigantografía de papel: Intervención colectiva para el 25N"
            href="/downloads/identificacionViolenciasDeGenero/Mural_de_papel_ Intervencion_colectiva_para_el_25N_(1).pdf"
          />
          <VideoButton
            href="/soymora"
            img
            src={Ver}
            texto="Ilustraciones a Voz Alzada. Ilustraciones por @soymora sobre Violencia Política"
          />
        </div>
        <hr className="mi-linea-horizontal" />
      </main>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default Index;
