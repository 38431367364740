import React from 'react'

const videoButton = ({ href, src, texto }) => {
    return (
        <a className="buttons encodesans-bold" href={href} target="_blank" >
            <img src={src} alt="Imagen del botón" />
            {texto}
        </a>
        );
    };
export default videoButton