import React, {useEffect, useState} from 'react';
import Header from '../../components/header/index';
import Footer from '../../components/footer/index';
import Buttons from '../../components/buttons/index';
import Nube from '../../images/web_micrositio_Observatorios_iconos-_04-NUBE.png';
import Title from '../../components/titles/index';

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // ajusta la posición del scroll a la parte superior de la página
  }, []);

  let [mainHeight, setMainHeight] = useState()

  useEffect(() => {
    setTimeout(() => {
      const mainElement = document.getElementById('height_view');
    
      if (mainElement) {
        setMainHeight(mainElement.clientHeight + 210);
      }
      console.log('El useEffect se ejecutó después de 3 segundos');
    }, 2000);
    
  }, []); 
    
    return (
    <>
        <Header height={mainHeight}/>
            <div className='title-aVozAlzada'>
                <h1 className='encodesans-bold'>
                <Title titulo="Promotores Territorialesde Género y Diversidad"/>
                </h1> 
            </div>
            <main className='main--solapas_custumizado' id="height_view">
            <section className='padding'>
              <div className='contenedor-buttons' id="width-view">
                  <Buttons  
                    img src={Nube}
                    texto='Guía de herramientas para la detección temprana de señales de las violencias de género'
                    href="/downloads/identificacionViolenciasDeGenero/Guia_de_herramientas_para_la_detección_de_señales_tempranas_de_las_violencias_por_motivos_de_genero.pdf"
                    /> 
                  <Buttons  
                    img src={Nube}
                    texto='Guía La violencia sexual es violencia de género'
                    href = "/downloads/identificacionViolenciasDeGenero/Guia_la_violencia_sexual_es_violencia_de_genero.pdf"
                  /> 
                  <Buttons  
                    img src={Nube}
                    texto='Guía Masculinidades sin violencia'
                    href ="/downloads/masculinidades/Guía de Masculinidades sin violencias_vertical.pdf"
                  /> 
              </div>
              <hr className="mi-linea-horizontal"/>
              </section>
          </main>
          <div>
            <Footer/>
          </div>
    </>
  )
}

export default Index